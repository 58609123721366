@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lato:wght@300;400;700&display=swap');

/* Import slick-carousel styles */
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #4CAF50;
  --color-secondary: #1A4314;
  --color-accent: #FFC107;
  --color-text-light: #1A4314;
  --color-text-dark: #FFFFFF;
}

/* Global styles */
body {
  font-family: 'Lato', sans-serif;
  color: var(--color-text-light);
  margin: 0;
  padding: 0;
  line-height: 1.6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  margin-bottom: 1rem;
  color: var(--color-secondary);
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }

/* Header styles */
.hamburger-menu {
  font-size: 1.5rem;
  padding: 0;
}

.social-icons {
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  .hamburger-menu {
    font-size: 1.75rem;
  }

  .social-icons {
    font-size: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .hamburger-menu {
    font-size: 2rem;
  }

  .social-icons {
    font-size: 1.75rem;
  }
}

/* Layout styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .container {
    padding: 0 2rem;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0 4rem;
  }
}

.section {
  padding: 4rem 1rem;
}

.section-alt {
  background-color: var(--color-secondary);
  color: var(--color-text-dark);
}

/* Button styles */
.btn {
  @apply font-bold py-2 px-4 rounded transition duration-300 ease-in-out;
}

.btn-primary {
  @apply bg-[var(--color-primary)] text-[var(--color-text-dark)] hover:bg-[var(--color-secondary)];
}

.btn-secondary {
  @apply bg-[var(--color-secondary)] text-[var(--color-text-dark)] hover:bg-[var(--color-primary)];
}

.btn-accent {
  @apply bg-[var(--color-accent)] text-[var(--color-text-light)] hover:brightness-110;
}

.btn-sm {
  @apply py-1 px-3 text-sm;
}

.btn-lg {
  @apply py-3 px-6 text-lg;
}

/* Section styles */
.section-title {
  @apply text-3xl md:text-4xl font-bold mb-8 text-center;
  color: var(--color-accent);
  position: relative;
  padding-bottom: 1rem;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: var(--color-accent);
}

/* Ensure the carousel container has a fixed width and hidden overflow */
.overflow-hidden {
  overflow: hidden;
  width: 100%;
}

/* Apply smooth transitions to the carousel items */
.flex {
  display: flex;
  transition: transform 0.5s ease;
}

/* Optimize for GPU acceleration */
.flex > * {
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
}

/* Style for individual carousel items */
.flex-shrink-0 {
  flex-shrink: 0;
  width: 16rem; /* w-64 */
  margin-left: 0.5rem; /* mx-2 */
  margin-right: 0.5rem; /* mx-2 */
}

/* Style for dish name captions */
.text-center {
  text-align: center;
  margin-top: 0.5rem; /* mt-2 */
  font-weight: 600; /* font-semibold */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Smooth transitions for hover effects */
.cursor-pointer {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.cursor-pointer:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

/* Prevent text selection during dragging */
.flex, .flex * {
  user-select: none;
}

/* Ensure the carousel doesn't interfere with other elements */
.mb-8 {
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
}

/* Optimize performance for animations */
@media (prefers-reduced-motion: no-preference) {
  .flex {
    transition: transform 0.5s linear;
  }
}

.header-scrolled {
  @apply shadow-md;
}

/* Showcase specific styles */
.video-carousel {
  margin-bottom: 2rem;
}

.video-carousel .slick-slide {
  padding: 0 10px;
}

.video-carousel video {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.masonry-grid_column > div {
  margin-bottom: 30px;
}

/* Lightbox styles */
.yarl__container {
  background-color: rgba(0, 0, 0, 0.9);
}

.yarl__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Adjust existing styles */
.section {
  padding: 4rem 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .section {
    padding: 2rem 0.5rem;
  }

  .masonry-grid {
    margin-left: -15px;
  }

  .masonry-grid_column {
    padding-left: 15px;
  }

  .masonry-grid_column > div {
    margin-bottom: 15px;
  }
}

/* About section styles */
.about-section {
  background: linear-gradient(135deg, rgba(26, 67, 20, 0.9) 0%, rgba(76, 175, 80, 0.9) 100%), url('./images/texture-image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: var(--color-text-dark);
  position: relative;
  overflow: hidden;
  min-height: 80vh;
  padding: 3rem 1rem;
}

.about-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.profile-card-container {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.profile-card {
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: var(--color-text-light);
  border: 2px solid var(--color-accent);
}

.about-cards-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
}

.about-card {
  background: linear-gradient(135deg, #adfda6 0%, #4CAF50 100%);
  border: 1px solid #FFD700;
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 1rem;
}

.about-card h3 {
  color: #FFD700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.about-card-content {
  background: linear-gradient(135deg, #bbf7c8e6 0%, rgba(76, 175, 80, 0.9) 100%);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  color: #1A4314;
}

/* Responsive adjustments */
@media (min-width: 640px) {
  .about-card {
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .about-card {
    max-width: 550px;
  }
}

@media (min-width: 1024px) {
  .about-card {
    max-width: 600px;
  }
}